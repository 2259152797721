import React, { useEffect, useState } from 'react';
import styles from '../style/Footer.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateShowSelectMode } from '../store/reducers/commonSlice';
import { Button, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export default function Footer() {
  const [menuDropdownMenus, setMenuDropdownMenus] = useState([]);
  const { selecting } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/info/futurehome') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectScheduleMode')}
            >
              Select Schedule
            </button>
          ),
          key: '0',
        }
      ]);
    } else if (location.pathname === '/info/notpaidhome') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectCreditMode')}
            >
              Select Credit
            </button>
          ),
          key: '0',
        },
      ]);
    } else if (location.pathname === '/info/historyhome') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectHistoryMode')}
            >
              Select History
            </button>
          ),
          key: '0',
        },
      ]);
    } else {
      setMenuDropdownMenus([]);
    }
  }, [location]);

  const handleShowSelectMode = (modeName) => {
    dispatch(updateShowSelectMode({
      modeName,
      show: true
    }));
  };

  return (
    <>
      {!selecting && (
        <>
          <div className={styles.footer}>
            <div className={styles.footerNav}>
              <div>
                <NavLink
                  activeClassName={styles.active}
                  to={'/info/futurehome'}
                >
                  <div>Schedule</div>
                </NavLink>
              </div>
              <div>
                <NavLink
                  activeClassName={styles.active}
                  to={'/info/notpaidhome'}
                >
                  <div>Credit</div>
                </NavLink>
              </div>
              <div>
                <NavLink
                  activeClassName={styles.active}
                  to={'/info/todolist'}
                >
                  <div>TodoList</div>
                </NavLink>
              </div>
              <div>
                <NavLink
                  activeClassName={styles.active}
                  to={'/info/historyhome'}
                >
                  <div>History</div>
                </NavLink>
              </div>
            </div>
          </div>
          {
            (['/info/futurehome', '/info/notpaidhome', '/info/todolist', '/info/historyhome'].includes(location.pathname)) && (
              <Dropdown menu={{ items: menuDropdownMenus }} trigger={['click']} placement="topLeft">
                <Button className={styles.footerBtn} shape="circle" icon={<MenuOutlined />} />
              </Dropdown>
            )
          }
        </>
      )}
    </>
  );
}
