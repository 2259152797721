import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Checkbox, Flex, message, Modal, Skeleton, Select, Dropdown } from 'antd';
import {
  CheckOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import styles from '../style/Home.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkDeleteInvoicesThunk,
  bulkUpdateInvoiceStatusThunk,
  getInvoiceListnotpaid,
  upAllInvoicesnotpaid, updateInvoicesDeliveryStatusThunk,
  updateSelectedInvoicesNotPaids,
} from '../store/reducers/invoiceSlice';
import { updateShowSelectMode } from '../store/reducers/commonSlice';

const { confirm } = Modal;
const { Option } = Select;

export default function NotpaidHome() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    product_details_summary_notpaid,
    invoicesNotPaid, // 修正变量名
    discountnotpaid,
    totalAmountnotpaid,
    loading,
    selectedInvoicesNotPaids
  } = useSelector((state) => state.invoice);
  const {
    showSelectCreditMode
  } = useSelector((state) => state.common);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getInvoiceListnotpaid());
  }, [dispatch]);

  const toMakeNewInvoice = () => {
    history.push('/makenotpaid');
  };

  const submit = () => {
    confirm({
      title: 'Submit confirm',
      icon: <ExclamationCircleFilled />,
      content: 'Confirm to Submit all invoices？',
      onOk() {
        messageApi.info('Submitting...');
        const params = invoicesNotPaid.map((it) => it.id);
        dispatch(upAllInvoicesnotpaid(params)).then(() => {
          messageApi.success('Submit Success');
          dispatch(getInvoiceListnotpaid());
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const viewProductDetailsSummary = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectAll = () => {
    dispatch(updateSelectedInvoicesNotPaids(invoicesNotPaid));
  };

  const handleSelectCancel = () => {
    dispatch(updateSelectedInvoicesNotPaids([]));
    dispatch(updateShowSelectMode({
      modeName: 'showSelectCreditMode',
      show: false
    }));
  };

  const handleDeliveryStatusChange = (value) => {
    console.log(`Selected delivery status: ${value}`);

    if (selectedInvoicesNotPaids.length > 0) {
      dispatch(
        updateInvoicesDeliveryStatusThunk({
          invoices: selectedInvoicesNotPaids.map(invoice => ({ id: invoice.id, delivery_status: value }))
        })
      ).then(() => {
        message.success("Update successful!")
      })
    } else {
      message.info("Please select at least one invoice.")
    }
  };

  const handleSubmissionOptionChange = (value) => {
    console.log(`Selected submission option: ${value}`);

    if (selectedInvoicesNotPaids.length > 0) {
      dispatch(
        bulkUpdateInvoiceStatusThunk({
          invoice_ids: selectedInvoicesNotPaids.map(invoice => invoice.id),
          payment_status: value
        })
      ).then(() => {
        message.success("Submit successful!")
      })
    } else {
      message.info("Please select at least one invoice.")
    }
  };

  const handleDelete = () => {
    if (selectedInvoicesNotPaids.length > 0) {
      confirm({
        title: 'Delete confirm',
        icon: <ExclamationCircleFilled />,
        content: 'Confirm delete the invoices?',
        async onOk() {
          dispatch(
            bulkDeleteInvoicesThunk({
              invoice_ids: selectedInvoicesNotPaids.map(invoice => invoice.id)
            })
          )
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      message.info("Please select at least one invoice.")
    }
  }

  const handleSelectChange = (value) => {
    if (selectedInvoicesNotPaids.includes(value)) {
      dispatch(updateSelectedInvoicesNotPaids(selectedInvoicesNotPaids.filter((invoice => invoice.id !== value.id))))
    } else {
      dispatch(updateSelectedInvoicesNotPaids([...selectedInvoicesNotPaids, value]))
    }
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.createBtn}
        onClick={toMakeNewInvoice}
        type="primary"
        icon={<PlusOutlined />}
      >
        添加新 Create New Invoice
      </Button>
      <div className={styles.content}>
        {loading ? (
          <Skeleton active />
        ) : (
          invoicesNotPaid.map((item) => (
            <Flex key={item.id} align="center" gap={10}>
              <Link
                to={{
                  pathname: '/makenotpaid',
                  state: { ...item, readonly: false },
                }}
                style={{flex: 1}}
              >
                <div
                  style={{
                    padding: 10,
                    backgroundColor: item.delivery_status === 'delivered' ? '#5cd28a' : item.delivery_status === 'in_transit' ? '#f3dc80' : '#a8b0b7',
                    borderRadius: 5,
                    margin: '5px auto 0 auto',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <p>{item.customer_name}</p>
                      <p>{item.invoice_date}</p>
                    </div>
                    <div>{item.total_amount}</div>
                  </div>
                </div>
              </Link>
              {showSelectCreditMode && <Checkbox checked={selectedInvoicesNotPaids.some((i) => i.id === item.id)} onChange={() => handleSelectChange(item)} />}
            </Flex>
          ))
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.priceWrapper}>
          <div>
            总折扣Total discount:{' '}
            <span className={styles.price}>{discountnotpaid}</span>
          </div>
          <div>
            总金额Total sales:{' '}
            <span className={styles.price}>{totalAmountnotpaid}</span>
          </div>
        </div>
        <Button onClick={submit} block type="primary" icon={<CheckOutlined />}>
          Submit All Notpaid Invoices
        </Button>
        <Button
          onClick={viewProductDetailsSummary}
          block
          type="default"
          className={styles.summaryBtn}
        >
          查看 View_product_details_summary
        </Button>
      </div>
      {contextHolder}

      {/* 模态框 */}
      <Modal
        title="Product Details Summary"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <ul>
          {product_details_summary_notpaid.map((item, index) => (
            <li key={index}>
              <strong>{item.name}</strong>: {item.total_quantity}
            </li>
          ))}
        </ul>
      </Modal>
      {
        showSelectCreditMode && (
          <div>
            <Flex className="actionTopLeft" onClick={handleSelectAll}>
              <Button type="primary">
                全选
              </Button>
            </Flex>
            <Flex className="actionTopRight">
              <Button type="primary" onClick={handleSelectCancel}>
                取消
              </Button>
            </Flex>
            <Flex className="actionBottomLeft">
              <Dropdown
                menu={{
                  items: [
                    { key: '0', label: 'Not Shipped', onClick: () => handleDeliveryStatusChange('not_shipped') },
                    { key: '1', label: 'In Transit', onClick: () => handleDeliveryStatusChange('in_transit') },
                    { key: '2', label: 'Delivered', onClick: () => handleDeliveryStatusChange('delivered') },
                  ]
                }}
                trigger={['click']}
                placement="topLeft"
              >
                <Button type="primary">标记送货状态</Button>
              </Dropdown>
              <Dropdown menu={{
                items: [
                  { key: '0', label: '提交到schedule', onClick: () => handleSubmissionOptionChange('future')},
                  { key: '1', label: '提交到credit', onClick: () => handleSubmissionOptionChange('notpaid') },
                  { key: '2', label: '提交到Getpaid', onClick: () => handleSubmissionOptionChange('pending') },
                  { key: '3', label: '提交到overdue', onClick: () => handleSubmissionOptionChange('overdue') },
                ]
              }} trigger={['click']} placement="topLeft">
                <Button type="primary">提交到</Button>
              </Dropdown>
            </Flex>
            <Flex className="actionBottomRight">
              <Button type="primary" onClick={handleDelete}>删除</Button>
            </Flex>
          </div>
        )
      }
    </div>
  );
}
