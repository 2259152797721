import { Modal, Button, Form, Input, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyInfo, updateShowCompanyInfoModal } from '../store/reducers/commonSlice';

function CompanyInfoModal() {
  const dispatch = useDispatch();

  const { showCompanyInfoModal, companyInfo } = useSelector((state) => state.common);

  const handleModalClose = () => {
    dispatch(updateShowCompanyInfoModal(false))
  }

  const onFinish = (values) => {
    dispatch(updateCompanyInfo(values))
    handleModalClose()
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title="Company Info"
      centered
      open={showCompanyInfoModal}
      onCancel={handleModalClose}
      okText="Save"
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={companyInfo}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Registration Number"
          name="registrationNumber"
          rules={[
            {
              required: true,
              message: 'Please input registration number!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bank Account"
          name="bankAccount"
          rules={[
            {
              required: true,
              message: 'Please input bank account!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bank Sort Code"
          name="bankSortCode"
          rules={[
            {
              required: true,
              message: 'Please input bank sort code!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Vat Number"
          name="vatNumber"
          rules={[
            {
              required: true,
              message: 'Please input vat number!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="Contact_number"
          rules={[
            {
              required: true,
              message: 'Please input contact number!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact Person"
          name="Contact_person"
          rules={[
            {
              required: true,
              message: 'Please input contact person!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Flex justify="flex-end">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}

export default CompanyInfoModal
