import { jsPDF } from 'jspdf';

export function generateRandomId() {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}

export function setpice(arr) {
  let zong = arr.reduce((total, item) => {
    total += item.quantity * (item.price - item.discount_amount);
    return total;
  }, 0);
  let discount = arr.reduce((total, item) => {
    total += item.quantity * item.discount_amount;
    return total;
  }, 0);
  return { zong, discount };
}

export function eqs(v1, v2) {
  if (
    typeof v1 !== 'object' ||
    v1 === null ||
    typeof v2 !== 'object' ||
    v2 === null
  ) {
    return v1 === v2;
  }
  let v1keys = Object.keys(v1);
  let v2keys = Object.keys(v2);
  if (v1keys.length !== v2keys.length) {
    return false;
  }
  for (let key of v1keys) {
    if (!v2keys.includes(key)) {
      return false;
    }
    if (!eqs(v1[key], v2[key])) {
      return false;
    }
  }
  return true;
}

export function getTotalAmountAndDiscountForInvoices(invoices) {
  let discount = 0;
  let totalAmount = invoices.reduce((prev, currentValue) => {
    prev += currentValue.total_amount;
    discount += currentValue.discount;
    return prev;
  }, 0);

  return {
    discount,
    totalAmount
  }
}

export function downloadFile(canvas, filename, format) {
  if (format === 'png') {
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/png');
    a.download = filename; // Use dynamic filename
    a.click();
  } else {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const pageWidth = 210;
    const pageHeight = 297;
    const aspectRatio = canvasWidth / canvasHeight;
    const pdfHeight = pageWidth / aspectRatio;
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    if (pdfHeight > pageHeight) {
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pageWidth, pageHeight);
    } else {
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pageWidth, pdfHeight);
    }

    pdf.save(filename);
  }
}

export function getDateRange(option) {
  const today = new Date();

  switch(option) {
    case 'this-week':
      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay())); // Start of the week (Sunday)
      const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6)); // End of the week (Saturday)
      return {
        start: formatDate(startOfWeek),
        end: formatDate(endOfWeek),
      };

    case 'last-week':
      const lastWeekStart = new Date(today.setDate(today.getDate() - today.getDay() - 7)); // Start of last week (Sunday)
      const lastWeekEnd = new Date(today.setDate(today.getDate() - today.getDay() - 1)); // End of last week (Saturday)
      return {
        start: formatDate(lastWeekStart),
        end: formatDate(lastWeekEnd),
      };

    case 'this-month':
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Start of the current month
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // End of the current month
      return {
        start: formatDate(startOfMonth),
        end: formatDate(endOfMonth),
      };

    case 'last-month':
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Start of last month
      const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0); // End of last month
      return {
        start: formatDate(lastMonth),
        end: formatDate(lastMonthEnd),
      };

    case 'last-3-months':
      const startOfLast3Months = new Date(today.getFullYear(), today.getMonth() - 3, 1); // Start of 3 months ago
      return {
        start: formatDate(startOfLast3Months),
        end: formatDate(today), // End is today
      };

    case 'this-year':
      const startOfYear = new Date(today.getFullYear(), 0, 1); // Start of this year
      const endOfYear = new Date(today.getFullYear(), 11, 31); // End of this year
      return {
        start: formatDate(startOfYear),
        end: formatDate(endOfYear),
      };

    case 'last-year':
      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1); // Start of last year
      const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31); // End of last year
      return {
        start: formatDate(startOfLastYear),
        end: formatDate(endOfLastYear),
      };

    case 'yesterday':
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      return {
        start: formatDate(yesterday),
        end: formatDate(yesterday),
      }

    default:
      return null;
  }
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
  return `${year}-${month}-${day}`;
}

