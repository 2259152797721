import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Checkbox, Flex, message, Modal, Skeleton, Select, Input, DatePicker } from 'antd';
import { CheckOutlined, ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import styles from '../style/Home.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkDeleteInvoicesThunk,
  bulkUpdateInvoiceStatusThunk, getInvoiceListHistory,
  getInvoiceListnotpaid,
  updateInvoicesDeliveryStatusThunk, updateOpen, updateSelectedInvoicesHistory,
  updateSelectedInvoicesNotPaids,
} from '../store/reducers/invoiceSlice';
import { updateShowSelectMode } from '../store/reducers/commonSlice';
import ChooseContactDrawer from '../components/ChooseContactDrawer';
import { getDateRange } from '../utils/utils';

const { confirm } = Modal;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function HistoryHome() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    product_details_summary_history,
    invoicesHistory,
    discountHistory,
    totalAmountHistory,
    loading,
    selectedInvoicesNotPaids,
  } = useSelector((state) => state.invoice);
  const { showSelectCreditMode } = useSelector((state) => state.common);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [dateRange, setDateRange] = useState(null);
  const [exactDate, setExactDate] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [customer, setCustomer] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  const [chooseCustomerModalOpen, setChooseCustomerModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getInvoiceListHistory());
  }, [dispatch]);

  const viewProductDetailsSummary = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectAll = () => {
    dispatch(updateSelectedInvoicesHistory(invoicesHistory));
  };

  const handleSelectCancel = () => {
    dispatch(updateSelectedInvoicesHistory([]));
    dispatch(
      updateShowSelectMode({
        modeName: 'showSelectCreditMode',
        show: false,
      })
    );
  };

  const handleDeliveryStatusChange = (value) => {
    if (selectedInvoicesNotPaids.length > 0) {
      dispatch(
        updateInvoicesDeliveryStatusThunk({
          invoices: selectedInvoicesNotPaids.map((invoice) => ({
            id: invoice.id,
            delivery_status: value,
          })),
        })
      ).then(() => {
        message.success('Update successful!');
      });
    } else {
      message.info('Please select at least one invoice.');
    }
  };

  const handleSubmissionOptionChange = (value) => {
    if (selectedInvoicesNotPaids.length > 0) {
      dispatch(
        bulkUpdateInvoiceStatusThunk({
          invoice_ids: selectedInvoicesNotPaids.map((invoice) => invoice.id),
          payment_status: value,
        })
      ).then(() => {
        message.success('Submit successful!');
      });
    } else {
      message.info('Please select at least one invoice.');
    }
  };

  const handleDelete = () => {
    if (selectedInvoicesNotPaids.length > 0) {
      confirm({
        title: 'Delete confirm',
        icon: <ExclamationCircleFilled />,
        content: 'Confirm delete the invoices?',
        async onOk() {
          dispatch(
            bulkDeleteInvoicesThunk({
              invoice_ids: selectedInvoicesNotPaids.map((invoice) => invoice.id),
            })
          );
        },
      });
    } else {
      message.info('Please select at least one invoice.');
    }
  };

  const handleSelectChange = (value) => {
    if (selectedInvoicesNotPaids.includes(value)) {
      dispatch(updateSelectedInvoicesHistory(selectedInvoicesNotPaids.filter((invoice) => invoice.id !== value.id)));
    } else {
      dispatch(updateSelectedInvoicesHistory([...selectedInvoicesNotPaids, value]));
    }
  };

  const handleDateRangeChange = (value) => {
    if (value === 'custom') {
      setIsCustomRange(true);
      setIsExactDate(false);
    } else if (value === 'exact') {
      setIsExactDate(true);
      setIsCustomRange(false);
    } else {
      setIsCustomRange(false);
      setIsExactDate(false);
    }
    setDateRange(value);
  };

  const handleFilterApply = () => {
    console.log('Filters Applied:');
    console.log('Date Range:', dateRange);
    console.log('Customer Name:', customerName);
    console.log('Payment Status:', paymentStatus);
    if (isCustomRange) {
      console.log('Custom Range:', dateRange);
    }
    if (isExactDate) {
      console.log('Exact Date:', exactDate);
    }
    let start_date, end_date, driver_customer_name, driver_customer_id;
    if (isCustomRange) {
      start_date = dateRange[0].format('YYYY-MM-DD')
      end_date = dateRange[1].format('YYYY-MM-DD')
    } else if (isExactDate) {
      start_date = exactDate.format('YYYY-MM-DD')
      end_date = exactDate.format('YYYY-MM-DD')
    } else {
      const range = getDateRange(dateRange)
      if (range) {
        start_date = range.start
        end_date = range.end
      }
    }

    if (customer) {
      driver_customer_name = customer.customer_name
      driver_customer_id = customer.id
    } else {
      driver_customer_name = customerName
    }

    dispatch(getInvoiceListHistory({
      start_date,
      end_date,
      driver_customer_name,
      driver_customer_id,
      payment_status: paymentStatus
    }));
  };

  const handleChooseContact = (obj) => {
    setCustomer(obj)
    setCustomerName(obj.customer_name)
  }

  return (
    <div className={styles.container}>
      {/* Filter Section */}
      <div className={styles.filterSection}>
        <h3>Invoice Filter/发票查询</h3>
        <div className={styles.filterField}>
          <label>Select Date Range/根据时间筛选</label>
          <Select
            style={{ width: '100%' }}
            placeholder="Select date range"
            onChange={handleDateRangeChange}
            allowClear
          >
            <Option value="this-week">This Week</Option>
            <Option value="last-week">Last Week</Option>
            <Option value="this-month">This Month</Option>
            <Option value="last-month">Last Month</Option>
            <Option value="last-3-months">Last 3 Months</Option>
            <Option value="this-year">This Year</Option>
            <Option value="last-year">Last Year</Option>
            <Option value="custom">Custom Range</Option>
            <Option value="exact">Exact Date</Option>
            <Option value="yesterday">Yesterday</Option>
          </Select>
          {isCustomRange && (
            <div style={{ marginTop: 16 }}>
              <RangePicker style={{ width: '100%' }} onChange={(dates) => setDateRange(dates)} />
            </div>
          )}
          {isExactDate && (
            <div style={{ marginTop: 16 }}>
              <DatePicker style={{ width: '100%' }} onChange={(date) => setExactDate(date)} />
            </div>
          )}
        </div>

        <div className={styles.filterField}>
          <label>客户/Customer Name</label>
          <Input
            placeholder="Enter name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <Button
            type="link"
            style={{ marginTop: -12 }}
            icon={<SearchOutlined />}
            onClick={() => setChooseCustomerModalOpen(true)}
          >
            Select from Contacts/从通讯录选择
          </Button>
        </div>

        <div className={styles.filterField}>
          <label>Payment Status</label>
          <Select
            style={{ width: '100%' }}
            placeholder="Select payment status"
            value={paymentStatus}
            onChange={(value) => setPaymentStatus(value)}
          >
            <Option value="">All</Option>
            <Option value="pending">Pending</Option>
            <Option value="paid">Paid</Option>
            <Option value="notpaid">Not Paid</Option>
            <Option value="overdue">Overdue</Option>
            <Option value="future">Future</Option>
          </Select>
        </div>

        <Button type="primary" block onClick={handleFilterApply}>
          Apply Filter
        </Button>
      </div>

      {/* Existing Invoice List */}
      <div className={styles.content}>
        {loading ? (
          <Skeleton active />
        ) : (
          invoicesHistory
            .slice()
            .sort((a, b) => new Date(a.invoice_date) - new Date(b.invoice_date)) // 按日期排序
            .reduce((acc, item, index, sortedList) => {
              const previousDate = index > 0 ? sortedList[index - 1].invoice_date : null;
              if (item.invoice_date !== previousDate) {
                acc.push(
                  <div key={`separator-${item.invoice_date}`} style={{ marginTop: 20, fontWeight: 'bold' }}>
                    --日期分割点--{item.invoice_date}
                  </div>
                );
              }
              acc.push(
                <Flex key={item.id} align="center" gap={10}>
                  <Link
                    to={{
                      pathname: '/updatehistory',
                      state: { ...item, readonly: false },
                    }}
                    style={{ flex: 1 }}
                  >
                    <div
                      style={{
                        padding: 10,
                        backgroundColor:
                          item.delivery_status === 'delivered'
                            ? '#5cd28a'
                            : item.delivery_status === 'in_transit'
                              ? '#f3dc80'
                              : '#a8b0b7',
                        borderRadius: 5,
                        margin: '5px auto 0 auto',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <p>{item.customer_name || 'Unnamed Customer'}</p>
                          <p>{item.invoice_date}</p>
                        </div>
                        <div>{item.total_amount}</div>
                      </div>
                    </div>
                  </Link>
                  {showSelectCreditMode && (
                    <Checkbox
                      checked={selectedInvoicesNotPaids.some((i) => i.id === item.id)}
                      onChange={() => handleSelectChange(item)}
                    />
                  )}
                </Flex>
              );
              return acc;
            }, [])
        )}
      </div>

      <div className={styles.bottom}>
        <div className={styles.priceWrapper}>
          <div>
            总折扣 Total discount: <span className={styles.price}>{discountHistory}</span>
          </div>
          <div>
            总金额 Total sales: <span className={styles.price}>{totalAmountHistory}</span>
          </div>
        </div>
        <Button
          onClick={viewProductDetailsSummary}
          block
          type="default"
          className={styles.summaryBtn}
        >
          查看 View_product_details_summary
        </Button>
      </div>

      <Modal
        title="Product Details Summary"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <ul>
          {product_details_summary_history.map((item, index) => (
            <li key={index}>
              <strong>{item.name}</strong>: {item.total_quantity}
            </li>
          ))}
        </ul>
      </Modal>
      <ChooseContactDrawer
        SetCustomer={handleChooseContact}
        type='type1'
        open={chooseCustomerModalOpen}
        onClose={() => setChooseCustomerModalOpen(false)}
      />
    </div>
  );
}
