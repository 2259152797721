import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, message, Flex, Modal, Skeleton, Checkbox, Select, Dropdown } from 'antd';
import {
  CheckOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import styles from '../style/Home.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkDeleteInvoicesThunk,
  bulkUpdateInvoiceStatusThunk, createInvoice,
  getInvoiceList, getInvoiceListfuture,
  upAllInvoices, updateInvoicesDeliveryStatusThunk,
  updateSelectedInvoices
} from '../store/reducers/invoiceSlice';
import { updateShowSelectMode } from '../store/reducers/commonSlice';
import { deleteContact } from '../store/reducers/contactSlice';

const { confirm } = Modal;
const { Option } = Select;

export default function Home() {
  let history = useHistory();
  const dispatch = useDispatch();
  const { product_details_summary, invoices, discount, totalAmount, loading, selectedInvoices, invoicesFuture } =
    useSelector((state) => state.invoice);
  const {
    showSelectGetPaidMode
  } = useSelector((state) => state.common);

  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false); // 控制模态框可见性
  const [isImportModalVisible, setIsImportModalVisible] = useState(false); // 控制导入模态框可见性

  useEffect(() => {
    dispatch(getInvoiceList());
    dispatch(getInvoiceListfuture());
  }, [dispatch]);

  function toMakeNewInvoice() {
    history.push('/make');
  }

  function submit() {
    confirm({
      title: 'Submit confirm',
      icon: <ExclamationCircleFilled />,
      content: 'Confirm to Submit all invoices？',
      onOk() {
        messageApi.info('Submiting...');
        let params = invoices.map((it) => it.id);
        dispatch(upAllInvoices(params)).then(() => {
          messageApi.success('Submit Success。。。');
          dispatch(getInvoiceList());
          dispatch(getInvoiceListfuture());
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function viewProductDetailsSummary() {
    setIsModalVisible(true); // 打开模态框
  }

  function handleModalClose() {
    setIsModalVisible(false); // 关闭模态框
  }

  function handleImportModalClose() {
    setIsImportModalVisible(false); // 关闭导入模态框
  }

  function handleImportButtonClick() {
    setIsImportModalVisible(true); // 打开导入模态框
  }

  const handleSelectAll = () => {
    dispatch(updateSelectedInvoices(invoices));
  };

  const handleSelectCancel = () => {
    dispatch(updateSelectedInvoices([]));
    dispatch(updateShowSelectMode({
      modeName: 'showSelectGetPaidMode',
      show: false
    }));
  };

  const handleDeliveryStatusChange = (value) => {
    console.log(`Selected delivery status: ${value}`);
    if (selectedInvoices.length > 0) {
     dispatch(
       updateInvoicesDeliveryStatusThunk({
         invoices: selectedInvoices.map(invoice => ({ id: invoice.id, delivery_status: value }))
       })
     ).then(() => {
       message.success("Update successful!")
     })
    } else {
      message.info("Please select at least one invoice.")
    }
  };

  const handleSubmissionOptionChange = (value) => {
    console.log(`Selected submission option: ${value}`);
    if (selectedInvoices.length > 0) {
      dispatch(
        bulkUpdateInvoiceStatusThunk({
          invoice_ids: selectedInvoices.map(invoice => invoice.id),
          payment_status: value
        })
      ).then(() => {
        message.success("Submit successful!")
      })
    } else {
      message.info("Please select at least one invoice.")
    }
  };

  const handleSelectChange = (value) => {
    if (selectedInvoices.includes(value)) {
      dispatch(updateSelectedInvoices(selectedInvoices.filter((invoice => invoice.id !== value.id))))
    } else {
      dispatch(updateSelectedInvoices([...selectedInvoices, value]))
    }
  }

  const handleDelete = () => {
    if (selectedInvoices.length > 0) {
      confirm({
        title: 'Delete confirm',
        icon: <ExclamationCircleFilled />,
        content: 'Confirm delete the invoices?',
        async onOk() {
          dispatch(
            bulkDeleteInvoicesThunk({
              invoice_ids: selectedInvoices.map(invoice => invoice.id)
            })
          )
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      message.info("Please select at least one invoice.")
    }
  }

  const handleImportFromSchedule = (item) => {
    messageApi.info('Saving。。。');
    const { id, payment_status, delivery_status, ...params } = item;
    dispatch(createInvoice(params)).then(() => {
      messageApi.success('Save success。。。');
      handleImportModalClose();
      dispatch(getInvoiceList());
    });
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.createBtn}
        onClick={toMakeNewInvoice}
        type="primary"
        icon={<PlusOutlined />}
      >
        添加新Create New Invoice
      </Button>
      <Button
        className={styles.importBtn} // 添加样式类
        onClick={handleImportButtonClick}
        type="default"
      >
        从schedule导入 Import
      </Button>
      <div className={styles.content}>
        {loading ? (
          <Skeleton active />
        ) : (
          invoices.map((item) => (
            <Flex key={item.id} align="center" gap={10}>
              <Link
                to={{ pathname: '/make', state: { ...item, readonly: false } }}
                style={{flex: 1}}
              >
              <div
                style={{
                  padding: 10,
                  backgroundColor: item.delivery_status === 'delivered' ? '#5cd28a' : item.delivery_status === 'in_transit' ? '#f3dc80' : '#a8b0b7',
                  borderRadius: 5,
                  margin: '5px auto 0 auto',
                  width: '100%',
                }}
              >
                <Flex align={'center'} justify={'space-between'}>
                  <div>
                    <p>{item.customer_name}</p>
                    <p>{item.invoice_date}</p>
                  </div>
                  <div>{item.total_amount}</div>
                </Flex>
              </div>
              </Link>
              {showSelectGetPaidMode && <Checkbox checked={selectedInvoices.some((i) => i.id === item.id)} onChange={() => handleSelectChange(item)} />}
            </Flex>
          ))
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.priceWrapper}>
          <div>
            总折扣Total discount:{' '}
            <span className={styles.price}>{discount}</span>
          </div>
          <div>
            总金额Total Sales:{' '}
            <span className={styles.price}>{totalAmount}</span>
          </div>
        </div>
        <Button onClick={submit} block type="primary" icon={<CheckOutlined />}>
          Submit All Today Invoice
        </Button>
        <Button
          onClick={viewProductDetailsSummary}
          block
          type="default"
          className={styles.summaryBtn} // 添加这个样式类
        >
          查看view_product_details_summary
        </Button>
      </div>
      {contextHolder}

      {/* 模态框 */}
      <Modal
        title="Product Details Summary"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null} // 隐藏默认的底部按钮
      >
        <ul>
          {product_details_summary.map((item, index) => (
            <li key={index}>
              <strong>{item.name}</strong>: {item.total_quantity}
            </li>
          ))}
        </ul>
      </Modal>

      {/* 导入模态框 */}
      <Modal
        title="从schedule导入Import"
        visible={isImportModalVisible}
        onCancel={handleImportModalClose}
        footer={null} // 隐藏默认的底部按钮
      >
        {
          invoicesFuture.map(item => (
            <Flex key={item.id} align="center" gap={10} onClick={() => handleImportFromSchedule(item)}>
              <div
                style={{
                  padding: 10,
                  backgroundColor: '#a8b0b7',
                  borderRadius: 5,
                  margin: '5px auto 0 auto',
                  width: '100%',
                }}
              >
                <Flex align={'center'} justify={'space-between'}>
                  <div>
                    <p>{item.customer_name}</p>
                    <p>{item.invoice_date}</p>
                  </div>
                  <div>{item.total_amount}</div>
                </Flex>
              </div>
            </Flex>
          ))
        }
      </Modal>

      {
        showSelectGetPaidMode && (
          <div>
            <Flex className="actionTopLeft" onClick={handleSelectAll}>
              <Button type="primary">
                全选
              </Button>
            </Flex>
            <Flex className="actionTopRight">
              <Button type="primary" onClick={handleSelectCancel}>
                取消
              </Button>
            </Flex>
            <Flex className="actionBottomLeft">
              <Dropdown
                menu={{
                  items: [
                    { key: '0', label: 'Not Shipped', onClick: () => handleDeliveryStatusChange('not_shipped') },
                    { key: '1', label: 'In Transit', onClick: () => handleDeliveryStatusChange('in_transit') },
                    { key: '2', label: 'Delivered', onClick: () => handleDeliveryStatusChange('delivered') },
                  ]
                }}
                trigger={['click']}
                placement="topLeft"
              >
                <Button type="primary">标记送货状态</Button>
              </Dropdown>
              <Dropdown menu={{
                items: [
                  { key: '0', label: '提交到schedule', onClick: () => handleSubmissionOptionChange('future')},
                  { key: '1', label: '提交到credit', onClick: () => handleSubmissionOptionChange('notpaid') },
                  { key: '2', label: '提交到Getpaid', onClick: () => handleSubmissionOptionChange('pending') },
                  { key: '3', label: '提交到overdue', onClick: () => handleSubmissionOptionChange('overdue') },
                ]
              }} trigger={['click']} placement="topLeft">
                <Button type="primary">提交到</Button>
              </Dropdown>
            </Flex>
            <Flex className="actionBottomRight">
              <Button type="primary" onClick={handleDelete}>删除</Button>
            </Flex>
          </div>
        )
      }
    </div>
  );
}
