import { Button, Flex, Modal, Skeleton } from 'antd';
import { updateShowViewModal } from '../store/reducers/contactSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../style/Home.module.css';
import { Link } from 'react-router-dom';
import { getTotalAmountAndDiscountForInvoices } from '../utils/utils';

function ViewCustomerInvoicesModal() {
  const { showViewModal, customerInvoiceDetail, customerInvoicesLoading } = useSelector((state) => state.contact);
  const dispatch = useDispatch();

  const { discount, totalAmount } = getTotalAmountAndDiscountForInvoices(customerInvoiceDetail.invoices || [])

  return (
    <Modal
      title="View Contact invoices"
      open={showViewModal}
      onCancel={() => dispatch(updateShowViewModal(false))}
      footer={[
        <Button key="close" onClick={() => dispatch(updateShowViewModal(false))}>
          Close
        </Button>,
      ]}
    >
      <div className={styles.content}>
        {customerInvoicesLoading ? (
          <Skeleton active />
        ) : (
          customerInvoiceDetail.invoices && customerInvoiceDetail.invoices.map((item) => (
            <Link
              key={item.id}
              to={{ pathname: '/make', state: { ...item, readonly: false } }}
            >
              <Flex align="center" gap={10}>
                <div
                  style={{
                    padding: 10,
                    backgroundColor: '#a8b0b7',
                    borderRadius: 5,
                    margin: '5px auto 0 auto',
                    width: '100%',
                  }}
                >
                  <Flex align={'center'} justify={'space-between'}>
                    <div>
                      <p>{item.customer_name}</p>
                      <p>{item.invoice_date}</p>
                    </div>
                    <div>{item.total_amount}</div>
                  </Flex>
                </div>
              </Flex>
            </Link>
          ))
        )}
      </div>
      <div className={styles.bottom} style={{position: 'absolute'}}>
        <div className={styles.priceWrapper}>
          <div>
            总折扣Total discount:{' '}
            <span className={styles.price}>{discount}</span>
          </div>
          <div>
            总金额Total Sales:{' '}
            <span className={styles.price}>{totalAmount}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewCustomerInvoicesModal
